<template>
  <div class="footer">
    <div class="links">
      <a @click="goRelate">关于我们</a>
      <a @click="goServe">服务协议</a>
      <a @click="goPrivacy">隐私政策</a>
    </div>
    <div class="copyright">
      Copyright © 2019 上海萌泰数据科技股份有限公司 ©版权所有.
      <a
        href=" http://beian.miit.gov.cn"
        target="_blank"
        style="color: #493185"
      >
        沪ICP备09099644号
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {
    // 隐私政策
    goPrivacy() {
      // this.$router.push("/privacy");
      window.open("https://www.ringdata.com/privacy_policy");
    },
    goRelate() {
      window.open("http://www.monetware.com/page/introduction.html");
    },
    goServe() {
      window.open("https://www.ringdata.com/user_agreement");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 60px;
  padding: 5px 0px;
  background-color: #eaeaea;
  .links {
    text-align: center;
    a {
      color: #010101;
      font-size: 13px;
      text-decoration: none;
    }
    a ~ a {
      margin-left: 15px;
    }
  }
  .copyright {
    text-align: center;
    font-size: 12px;
    padding: 3px 0px 0px 0px;
  }
}
</style>
