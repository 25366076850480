<template>
  <div class="container">
    <!-- 头部 -->
    <!-- <top /> -->
    <div class="topBackground">
      <h1>锐研·云采集定位助手</h1>
      <h2>谷歌浏览器插件</h2>
      <p>一键获取元素Xpath，自定义Xpath编写，实时预览内容。</p>
      <button class="download" @click="downloadPlugin">
        <img src="../../assets/imgs/download-button.svg" alt="" />
        立即下载
      </button>
      <span>目前仅支持谷歌浏览器安装使用，详细使用教程查看帮助中心</span>
      <img
        class="download-img"
        src="../../assets/imgs/download_img.png"
        alt=""
      />
    </div>
    <div class="main">
      <div class="main-content">
        <p>
          在下载文件夹中找到已下载的插件，解压压缩包，得到一个名为ringdata_extension文件夹。
        </p>
        <img
          class="main-show-how"
          src="../../assets/imgs/download-referral-1.png"
          alt=""
        />
        <p>打开谷歌浏览器，点击右上方菜单按钮，选择更多工具—扩展程序。</p>
        <img
          class="main-show-how"
          src="../../assets/imgs/download-referral-2.png"
          alt=""
        />
        <p>
          在扩展程序页面点击左上方，点击加载已解压的扩展程序，选择刚刚解压好的ringdata_extension文件夹。
        </p>
        <img
          class="main-show-how"
          src="../../assets/imgs/download-referral-3.png"
          alt=""
        />
        <p>安装成功后，将呈现如下状态。</p>
        <img
          class="main-show-how"
          src="../../assets/imgs/download-referral-4.png"
          alt=""
        />
        <p>
          打开一个新网页，点击右上角插件即可开始使用。也可以点击插件右侧的图钉按钮，固定在工具栏，方便快速调用。
          <br />
          如果是已经打开的网页，点击插件会没有反应，刷新页面后，再点击插件即可正常使用。
        </p>
        <div style="display: flex; align-items: flex-end; margin-left: 30px">
          <img
            style="width: 300px"
            src="../../assets/imgs/download-referral-5.png"
            alt=""
          />
          <img
            style="width: 550px"
            src="../../assets/imgs/download-referral-6.png"
            alt=""
          />
        </div>
        <div class="main-tail">
          <div>
            <p>
              如果刷新页面无法使用，则建议关闭所有页面和浏览器，重启浏览器后尝试。如果还不行，可以尝试重启电脑，或重装插件。
            </p>
            <p>
              您有任何意见或者问题，可以联系我们的官方微信客服，微信号monetware-service。
            </p>
          </div>
          <img src="../../assets/imgs/download-QRcode.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <bottom />
  </div>
</template>

<script>
import top from "../common/header";
import bottom from "../common/footer";

export default {
  components: {
    top,
    bottom,
  },
  data() {
    return {};
  },
  methods: {
    downloadPlugin() {
      window.open("https://cdn.ringdata.com/ringspider/ringdata_extension.zip");
      // console.log('download plugin')
    },
  },
};
</script>
<style scoped>
.topBackground {
  height: 469px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7ff;
}
h1,
h2 {
  margin: 0;
  z-index: 2;
  color: #101010;
}
.topBackground h1 {
  font-size: 36px;
}
.topBackground h2 {
  font-size: 28px;
}
.topBackground p {
  font-size: 20px;
  color: #333333;
  z-index: 2;
}
.topBackground span {
  margin-top: 12px;
  font-size: 12px;
  color: #8c8c8c;
  z-index: 2;
}
.topBackground .download {
  width: 196px;
  height: 64px;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  background-color: #760478;
  z-index: 2;
}
.topBackground .download {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
}
.topBackground .download img {
  width: 32px;
}
.download-img {
  position: absolute;
  width: 349px;
  height: 304px;
  left: 15%;
  top: 100px;
  z-index: 1;
}
.main .main-content {
  width: 80%;
  margin: 0 auto;
}
.main .main-content .main-show-how {
  width: 60%;
  margin-left: 42px;
}

.main .main-content .main-tail {
  margin-top: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-tail img {
  width: 214px;
}
.container {
  max-width: none !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 0;
}
.container .main {
  background-color: #fff;
  width: 1200px;
  margin: 0 auto;
  padding: 10px 15px;
  flex: 1;
}
.container .main .bread .menus {
  margin-top: 10px;
  padding-left: 0px;
  overflow: hidden;
}
.container .main .bread .menus li {
  float: left;
  list-style: none;
}
.container .main .bread .menus li a {
  color: #000;
}
.container .main .searchBtn {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 8px;
  border: none;
}
p {
  margin: 20px 0;
}
</style>
